<template>
  <div>      
    <CRow v-if="!viewFieldMode">
      <CCol lg="12">
        <transition name="fade">
          <CCard>
            <CCardHeader>
              <CIcon name="cil-lan" /> Equipamentos
              <div class="card-header-actions">
                <CLink class="card-header-action btn-minimize" @click="formCollapsed=!formCollapsed">
                  <CIcon :name="`cil-chevron-${formCollapsed ? 'bottom' : 'top'}`"/>
                </CLink>
                <CLink href="#" class="card-header-action btn-close" @click="Fechar">
                  <CIcon name="cil-x-circle"/>
                </CLink>
              </div>
            </CCardHeader>
            <CCollapse :show="formCollapsed">
              <CCardBody>
                <CRow class="align-items-center">
                  <CCol sm="3" md="2" class="mb-3 mb-xl-0">
                    <CButton block color="dark" shape="pill" @click="newEquip">Novo</CButton>
                  </CCol>
                </CRow>
                <CRow class="align-items-center mt-3">
                  <CCol  sm="12" class="mb-3 mb-xl-0">
                    <CDataTable
                        :hover="hover"
                        :striped="striped"
                        :border="border"
                        :small="small"
                        :fixed="fixed"
                        :items="items"
                        :fields="fields"
                        :items-per-page="small ? 10 : 5"
                        :dark="dark"
                        :noItemsView="noItemsView"
                        pagination
                    >
                        <template #active="{item}">
                            <td>
                                <CBadge v-if="item.active===true" :color="getBadge(item.active)">Ativo</CBadge>
                                <CBadge v-else-if="item.active===false" :color="getBadge(item.active)">Inativo</CBadge>
                            </td>
                        </template>
                        <template #company="{item}" v-if="isFullDesktop">
                            <td v-if="item.company.company_name.length > 35">
                              {{item.company.company_name.substring(0,35)}}
                              <a v-c-tooltip.hover.click="item.company.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company.company_name}}
                            </td>
                        </template>
                        <template #company="{item}" v-else>
                            <td v-if="item.company.company_name.length > 20">
                              {{item.company.company_name.substring(0,20)}}
                              <a v-c-tooltip.hover.click="item.company.company_name">
                                ...
                              </a>
                            </td>
                            <td v-else>
                              {{item.company.company_name}}
                            </td>
                        </template>
                        <template #actions="{item}">
                            <td>
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'ex'+item.id"
                                  color="danger"
                                  v-if="item.active===true"
                                  @click="deactivateUser(item.id)"
                                >
                                  <CIcon size="sm" name="cil-trash"/>
                                </CButton>
                                <CButton
                                  :name=item.id
                                  size="sm"
                                  :key="'vi'+item.id"
                                  color="success"
                                  v-if="item.active===true"
                                  @click="viewToken(item)"
                                >
                                  <CIcon size="sm" name="cib-matternet"/>
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCollapse>
          </CCard>
        </transition>
      </CCol>
    </CRow>
    <CRow v-else>
      <CCol lg="12">        
        <CCard>
          <CCardHeader>
            <CIcon name="cil-lan" /> Equipamentos(login)
            <div class="card-header-actions">
              <CLink href="#" class="card-header-action btn-close" @click="viewFieldMode=false">
                <CIcon name="cil-x-circle"/>
              </CLink>
            </div>
          </CCardHeader>          
            <CCardBody>
              <CRow class="align-items-center">
                <CCol  sm="3" md="2" class="mb-3 mb-xl-0">
                  <CButton block color="dark" shape="pill" @click="viewFieldMode = false">Desistir</CButton>
                </CCol>
              </CRow>
              <CRow class="align-items-center mt-3">
                <CCol  sm="12" class="mb-3 mb-xl-0">
                 
                </CCol>
              </CRow>
            </CCardBody>          
        </CCard>       
      </CCol>
    </CRow>
    <CModal title="Chave" color="info" :show.sync="showToken">
      <CInput label="CNPJ:" placeholder="CNPJ" disabled :value="cnpj" />
      <CInput label="Equipamento:" placeholder="Número de série" disabled :value="nameEquip" />
      <CInput label="Conta ID:" placeholder="Conta ID" disabled :value="id_account" />
      <CInput label="Alias:" placeholder="Alias" disabled :value="pix" />
      <CTextarea
          label="Chave de Acesso:"
          placeholder="Chave de Acesso"
          rows="4"
          :value="acessKey"
          disabled
        />
      <template #footer>
        <CButton @click="showToken = false" color="danger">Sair</CButton>
      </template>
    </CModal>
    <CModal title="Cadastro de Equipamentos" color="info" :show.sync="showNewEqui">
      <CSelect label="Empresa" :options="listCompany" :value.sync="equip.idNewCompany" />
      <CInput label="Número de série" placeholder="Número de série" v-model="equip.serie" />
      <template #footer>
        <CButton @click="showNewEqui = false" color="danger">Desistir</CButton>
        <CButton @click="saveNewEquip" color="primary">Salvar</CButton>
      </template>
    </CModal>
    <CModal title="Aviso!" color="danger" :show.sync="showAlert">
      {{ msg }}
      <template #footer>
        <CButton @click="showAlert = false" color="danger">OK</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;

//import "regenerator-runtime";

export default {
  name: 'Equipamentos',
  components:{},
  data () {
    return {
      viewFieldMode: false,
      formCollapsed: true,
      hover: false,
      border: false,
      small: false,
      striped: false,
      fixed: false,
      dark: false,
      items:[],
      noItemsView:{ 
          noResults: 'Nenhum resultado para este filtro', 
          noItems: 'Nenhuma usuário/equipamento cadastrado' 
      },
      isMobile: false,
      isFullDesktop: false,
      fields: [
        {key: 'id', label: '#'}, 
        {key: 'company', label: 'Empresa'},
        {key: 'name', label: 'Nome/Equipamento'}, 
        {key: 'active'      , label: 'Ativo'},
        {key: 'actions'     , label: 'Ações'}
      ],
      msg: '',
      showAlert: false,
      showNewEqui: false,
      equip:{serie:'', idNewCompany:0},
      listCompany:[],
      showToken: false,
      acessKey:'',
      nameEquip:'',
      id_account:'',
      alias:'',
      pix:'',
      cnpj: '',
    }
  },  
  methods: {
    ...functions,
    getData: function () {
      const token = localStorage.getItem('user-token');
      axios({
        method: 'get',
        url: this.vhost('transaction/user/equipment'),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then((res) => {
        this.items= res.data.data;
      })
      .catch(this.onError);
    },
    Fechar(){
      return
    },
    newEquip(){
      const token = localStorage.getItem('user-token');
      this.equip.idNewCompany = 0;
      this.equip.serie = '';
      axios({
        method: 'get',
        url: this.vhost('company'),
        headers: { "Content-Type": "application/json","x-access-token": token},
      })
      .then((res) => {
        this.listCompany= new Array;
        this.listCompany.push({value:0, label: 'Selecione...'})
        res.data.data.map(e=>{
          this.listCompany.push({value:e.id, label: e.company_name})
        })
      });
      this.showNewEqui = true;
    },
    saveNewEquip(){
      if (this.equip.idNewCompany===0){
        return this.setAlert('Selecione uma empresa.')
      }
      if (this.equip.serie===''){
        return this.setAlert('"Número de série" não pode estar em branco')
      }
      const token = localStorage.getItem('user-token');
      const data = {
        company_id: this.equip.idNewCompany,
        serie: this.equip.serie,
      };
      axios({
        method: 'post',
        url: this.vhost('transaction/user/equipment'),
        headers: { "Content-Type": "application/json", "x-access-token": token},
        data
      })
      .then(() => {
        this.getData();
        this.showNewEqui = false;
      })
      .catch(this.onError);
    },
    deactivateUser(id){
      const token = localStorage.getItem('user-token');
      axios({
        method: 'delete',
        url: this.vhost(`transaction/user/equipment/${id}`),
        headers: { "Content-Type": "application/json", "x-access-token": token},
      })
      .then(() => {
        this.getData();
      })
      .catch(this.onError);      
    },
    viewToken(item){
      const {token, name} = item;
      const { cnpj } = item.company;
      const { account_id } = item.company.account;
      const  pix  = item.company.pix.name;
      this.acessKey= token;
      this.nameEquip= name;
      this.id_account = account_id;
      this.pix = pix;
      this.cnpj = this.formatCNPJ(cnpj);
      this.showToken=true;
    },
    onError(err){
      if (err.response && err.response.data && err.response.data.auth===false) {
        localStorage.setItem('user-token', '');
        this.$router.push('/admin/login');
      } else if (err.response && err.response.data && err.response.data.erro){
        this.setAlert(err.response.data.erro);        
      } else {
        this.setAlert(err.message);
      }  
    },
    onResize () {
        this.isMobile = window.innerWidth < 576;
        this.isFullDesktop = window.innerWidth > 1300;
    },
    getBadge (status) {
      switch (status) {
        case true: return 'success'
        case false: return 'secondary'
        default: 'primary'
      }
    },
    setAlert(message){
      this.msg=message;
      this.showAlert=(message !== undefined);
    },
  },
  created: function () {
    this.getData();
  },
  mounted () {
      this.onResize()
      window.addEventListener('resize', this.onResize, { passive: true })
  },  
}
</script>
